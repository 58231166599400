
import { defineComponent, ref } from 'vue';
import TextsForm from '@/components/TextsForm.vue';
import useTexts from '@/store/texts';
import { updateTexts } from '@/services/Texts';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    TextsForm,
    Loader,
  },
  setup() {
    const {
      texts,
      loadingText,
      getTexts,
      submited,
      isValid,
    } = useTexts();

    getTexts();

    const savingTexts = ref(false);

    const save = async () => {
      submited.value = true;

      if (isValid.value && texts.data) {
        savingTexts.value = true;

        await updateTexts(texts.data);

        savingTexts.value = false;
      }
    };

    return {
      texts,
      loadingText,
      savingTexts,
      save,
    };
  },
});
