import { reactive, ref, Ref } from 'vue';
import * as TextsService from '@/services/Texts';
import { TextsInterface, TextInterface } from '@/types/functions';

const DEFAULT_TEXT: TextInterface = {
  title: '',
  paragraphs: [],
};

const DEFAULT_TEXTS: TextsInterface = {
  candidate: {
    info: DEFAULT_TEXT,
    pricing: DEFAULT_TEXT,
    consultation: DEFAULT_TEXT,
  },
  company: {
    contact: DEFAULT_TEXT,
    info: DEFAULT_TEXT,
    pricing: DEFAULT_TEXT,
  },
  home: {
    home: DEFAULT_TEXT,
  },
};

const texts = reactive<{ data: TextsInterface }>({ data: DEFAULT_TEXTS });
const loadingText = ref(0);

const submited = ref(false);
const isValid = ref(false);

export default function useTexts(): {
  texts: { data: TextsInterface | null };
  loadingText: Ref<number>;
  getTexts: () => Promise<void>;
  submited: Ref<boolean>;
  isValid: Ref<boolean>;
  } {
  async function getTexts(): Promise<void> {
    loadingText.value += 1;

    const data = await TextsService.getTexts();

    console.log(data);

    texts.data = data || DEFAULT_TEXTS;

    loadingText.value -= 1;
  }

  return {
    texts,
    loadingText,
    getTexts,
    submited,
    isValid,
  };
}
