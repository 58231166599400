
import useTexts from '@/store/texts';
import { TextInterface } from '@/types/functions';
import {
  defineComponent,
  reactive,
  ref,
  watch,
  PropType,
} from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<TextInterface>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const {
      submited,
      isValid,
    } = useTexts();

    // FORM DATA
    const editableTitle = ref(props.modelValue.title || '');
    const editableParagraphs = ref<string[]>(props.modelValue.paragraphs || []);

    // VALIDATION
    const validations = reactive<{
      title: string;
      paragraphs: string[];
    }>({
      title: '',
      paragraphs: [],
    });

    function validateTitle() {
      if (!editableTitle.value) {
        validations.title = 'Nadpis musí být vyplněn';
      } else {
        validations.title = '';
      }
    }

    function validateParagraphs() {
      editableParagraphs.value.forEach((paragraph, index) => {
        if (!paragraph) {
          validations.paragraphs[index] = 'Odstavec nesmí být prázdný';
        } else {
          validations.paragraphs[index] = '';
        }
      });
    }

    function validate() {
      validateTitle();
      validateParagraphs();

      isValid.value = !validations.title && validations.paragraphs.find((paragraph) => paragraph) === undefined;
    }

    validate();

    watch([editableTitle, editableParagraphs], () => {
      validate();
      emit('update:modelValue', {
        title: editableTitle.value,
        paragraphs: editableParagraphs.value,
      });
    }, { deep: true });

    return {
      editableTitle,
      editableParagraphs,
      validations,
      submited,
    };
  },
});
