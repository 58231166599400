import { functions } from '@/firebase';

export interface TextInterface {
  title: string;
  paragraphs: string[];
}

export interface TextsInterface {
  candidate: {
    info: TextInterface;
    pricing: TextInterface;
    consultation: TextInterface;
  }
  company: {
    contact: TextInterface;
    info: TextInterface;
    pricing: TextInterface;
  }
  home: {
    home: TextInterface;
  }
}

export async function getTexts(): Promise<TextsInterface> {
  const getTextsFN = functions.httpsCallable('getTexts');
  const { data }: { data: TextsInterface } = await getTextsFN();

  return data;
}

export async function updateTexts(texts: TextsInterface): Promise<void> {
  const updateTextsFN = functions.httpsCallable('updateTexts');

  await updateTextsFN(texts);
}
