<template>
  <div class="texts">
    <h1>Texty</h1>

    <p>Na této stránce se dají upravovat všechny texty (nezahrnuje texty ve formě obrázků). Pro formátování můžete využít HTML jazyk.</p>
    <p>Pro odkaz použijte: <b>{{ '<a href="www.google.com">google</a>' }}</b></p>
    <p>Pro email odkaz použijte: <b>{{ '<a href="mailto:info@hrnamiru.cz">info@hrnamiru.cz</a>' }}</b></p>
    <p>Pro tučný text použijte: <b>{{ '<b>Tučný text</b>' }}</b></p>

    <template v-if="!loadingText">
      <h2>Kandidáti</h2>

      <h3>Konzultace</h3>
      <TextsForm v-model="texts.data.candidate.consultation"/>

      <h3>Info</h3>
      <TextsForm v-model="texts.data.candidate.info"/>

      <h3>Ceník</h3>
      <TextsForm v-model="texts.data.candidate.pricing"/>

      <h2>Firmy</h2>

      <h3>Kontakt</h3>
      <TextsForm v-model="texts.data.company.contact"/>

      <h3>Info</h3>
      <TextsForm v-model="texts.data.company.info"/>

      <h3>Ceník</h3>
      <TextsForm v-model="texts.data.company.pricing"/>

      <h2>Úvod</h2>

      <TextsForm v-model="texts.data.home.home"/>
    </template>

    <button
      v-if="!loadingText"
      @click="save"
      :disabled="savingTexts"
      class="button texts__button"
    >
      Uložit
    </button>
  </div>

  <Loader v-if="loadingText || savingTexts"/>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import TextsForm from '@/components/TextsForm.vue';
import useTexts from '@/store/texts';
import { updateTexts } from '@/services/Texts';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    TextsForm,
    Loader,
  },
  setup() {
    const {
      texts,
      loadingText,
      getTexts,
      submited,
      isValid,
    } = useTexts();

    getTexts();

    const savingTexts = ref(false);

    const save = async () => {
      submited.value = true;

      if (isValid.value && texts.data) {
        savingTexts.value = true;

        await updateTexts(texts.data);

        savingTexts.value = false;
      }
    };

    return {
      texts,
      loadingText,
      savingTexts,
      save,
    };
  },
});
</script>

<style lang="scss" scoped>
.texts {
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    margin: 40px 0 0;
    color: $color-green;
  }

  h3 {
    margin: 40px 0 10px;
    font-size: 1.4rem;
    color: $color-blue;

    &:first-child {
      margin-top: 10px;
    }
  }

  &__button {
    margin-top: 20px;
    background-color: $color-green;
    color: $color-white;
  }
}
</style>
